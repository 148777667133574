import React from "react";
import { Divider } from "../../components/Divider";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function AccessibilityPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Accessibility"
        // description="Our work at Nielsen is to count and include everyone. For this reason, all of our GDS foundations, components, and patterns meet WCAG 2.2 standards for accessibility on the web. By adhering to this standard, we are raising the bar for Nielsen products and including as many users as possible."
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=19919%3A516907"
        tocItems={[
          "UI States",
          "Color Contrast",
          "Titles and Headings",
          "Input Assistance",
          "Assistive Technologies",
        ]}
      >
        <div>
          <p>
            Our work at Nielsen is to count and include everyone. For this
            reason, all of our GDS foundations, components, and patterns meet
            WCAG 2.2 AA standards for accessibility on the web. By adhering to this
            standard, we are raising the bar for Nielsen products and including
            as many users as possible. For more information about WCAG 2.2 AA, you
            can{" "}
            <gds-link
              underlined="true"
              size="600"
              href="https://www.w3.org/TR/WCAG22/"
            >
              read their guidance and requirements here.
            </gds-link>
          </p>
          <p>
            This isn’t an exhaustive list of all the accessibility standards in
            Nielsen. These are high level standards we’ve set for our
            applications. Please take time to read the WCAG 2.2 AA standards so you
            can incorporate its principles into our products.
          </p>
        </div>
        <Divider />
        <div className="section" id="ui-states">
          <h2>UI States</h2>
          <p>
            We've engineered all GDS components to have appropriate states of
            interaction. Each state has important guidance that applies to web
            accessibility.
          </p>
          <div className="mt-space-800">
            <h3>Hover: the pointer is above an element</h3>
            <p>
              Only informative interactions should occur only on hover. Hovering
              is most often used for giving subtle feedback to the user about
              clickable elements.
            </p>
            <gds-link
              href="https://www.w3.org/TR/WCAG22/#content-on-hover-or-focus"
              size="600"
              underlined="true"
            >
              Learn more about hovering guidance
            </gds-link>
          </div>
          <div className="mt-space-800">
            <h3>Clicked: the state which the pointer is actively clicked</h3>
            <p>
              The clicked state (also known as a down-event) cannot be used to
              execute any part of a function. A function must be completed on
              the pointer's up-event.
            </p>
            <gds-link
              href="https://www.w3.org/TR/WCAG22/#pointer-cancellation"
              size="600"
              underlined="true"
            >
              Learn more about clicked/pointer guidance
            </gds-link>
          </div>
          <div className="mt-space-800">
            <h3>Disabled: an element is inactive to user interaction</h3>
            <p>
              There is no contrast requirement for inactive components. For this
              reason, we use a 32% opacity to denote inactive elements. Disabled
              objects don’t have any functionality. You can’t click, select,
              drag, etc. them in this state.
            </p>
            <gds-link
              href="https://www.w3.org/TR/WCAG22/#contrast-minimum"
              size="600"
              underlined="true"
            >
              Learn more about disabled guidance
            </gds-link>
          </div>
          <div className="mt-space-800">
            <h3>
              Drag: an interaction to move content that happens during the
              pointer's clicked state
            </h3>
            <p>
              Any functionality that happens via drag must also be able to be
              achieved with a pointer click as well. No interaction should
              solely be achieved by a drag.
            </p>
            <gds-link
              href="https://www.w3.org/TR/WCAG22/#dragging-movements"
              size="600"
              underlined="true"
            >
              Learn more about dragging guidance
            </gds-link>
          </div>
          <div className="mt-space-800">
            <h3>
              Keyboard Focus: visual affordance to show an element can receive
              user interaction
            </h3>
            <p>
              Focus states are only shown during keyboard navigation (like
              tabbing and arrowing) or when using an assistive technology.
            </p>
            <gds-link
              href="https://www.w3.org/TR/WCAG22/#focus-appearance-minimum"
              size="600"
              underlined="true"
            >
              Learn more about focus guidance
            </gds-link>
          </div>
        </div>
        <Divider />

        <div className="section" id="color-contrast">
          <h2>Color Contrast</h2>
          <p>
            Color contrast measures the relative difference between two hues of
            color. Higher contrast ratios are easier to read and perceive. All
            GDS components have been checked to ensure proper contrast ratios.
            Do not make changes without good reason. Always check to be sure
            you've provided proper contrast for text and UI elements.
          </p>
          <p>
            There are several contrast checker Figma plugins. We recommend
            Stark. Download the{" "}
            <gds-link
              href="https://www.figma.com/community/plugin/732603254453395948/Stark"
              underlined="true"
              size="600"
            >
              Stark plugin
            </gds-link>
            .
          </p>
          <p>
            All text must meet or exceed a 4.5:1 contrast ratio. Large text
            (size-600 and greater) must have a 3:1 contrast ratio or greater.
            User Interface and graphics must have at least a 3:1 contrast ratio
            to their backgrounds.
          </p>
          <p2 style={{ fontStyle: "italic" }}>What this means in GDS</p2>
          <ul>
            <li>
              In light mode, text must be gray-600 or darker (depending on
              background)
            </li>
            <li>
              In dark mode, text must be gray-500 or lighter (depending on
              background)
            </li>
            <li>
              For display icons with backgrounds, the default foreground icon
              color is -600, but may need to be updated for a 3:1 ratio.
            </li>
          </ul>
          <gds-link
            href="https://www.w3.org/TR/WCAG22/#contrast-minimum"
            underlined="true"
            size="600"
          >
            Learn more about WCAG Color Contrast Guidance
          </gds-link>
        </div>
        <Divider />

        <div className="section" id="titles-and-headings">
          <h2>Titles and Headings</h2>
          <p>
            Properly labeling a space grounds a user. It helps them easily
            understand the content they’re viewing and what tasks are available
            to them. They’re especially important for users of screen readers
            who will have this data read aloud to them. Omitting it makes it
            difficult for users to orient where they are in an application.
          </p>
          <h3>Every Page Should Have</h3>
          <ul>
            <li>
              A web page title (in the &lt;title&gt; tag) that includes the
              application name and page title. This is what shows in the browser
              tab.{" "}
            </li>
            <li>
              The application’s name in the global navigation. (This is not the
              page’s H1)
            </li>
            <li>
              A page title (wrapped in an &lt;h1&gt; tag) that names the space.
              (More information about this in our Writing Guidelines page.)
            </li>
            <li>
              Section headings, wherever applicable (wrapped in &lt;h2&gt; and
              descending tags)
            </li>
          </ul>

          <p2>
            There are two main options for how to structure the web page title:
          </p2>
          <ol>
            <li>
              &#123;name_of_application&#125; / &#123;title_of_the_page&#125;
            </li>
            <li>
              &#123;name_of_application&#125; /
              &#123;name_of_the_data_or_space_viewed&#125;
            </li>
          </ol>
          <p>
            Titles do not need to exactly match a page’s breadcrumbs. It’s more
            important they give the application name and the page they’re
            viewing.
          </p>
          <h4>Proper Web Page Title Examples</h4>
          <ul>
            <li>Nielsen One Ads / Campaigns List</li>
            <li>
              Digital Ad Ratings / Hooli - 2021 Quad Triple Edge Measurement
            </li>
            <li>Nielsen Outcomes Dashboard / Demo Mix Final Results</li>
          </ul>
          <h3>Further Reading</h3>
          <ul>
            <li>
              <gds-link
                href="https://www.w3.org/TR/WCAG22/#page-titled"
                underlined="true"
              >
                Success Criterion 2.4.2 Page Titled
              </gds-link>
              : Web pages have titles that describe topic or purpose.
            </li>
            <li>
              <gds-link
                href="https://www.w3.org/TR/WCAG22/#headings-and-labels"
                underlined="true"
              >
                Success Criterion 2.4.6 Headings and Labels
              </gds-link>
              : Headings and labels describe topic or purpose.
            </li>
          </ul>
        </div>
        <Divider />

        <div className="section" id="input-assistance">
          <h2>Input Assistance</h2>
          <p>
            These are some key areas of input accessibility that are important
            to Nielsen applications.
          </p>
          <ul>
            <li>
              <gds-link
                href="https://www.w3.org/TR/WCAG22/#error-prevention-legal-financial-data"
                underlined="true"
              >
                Success Criterion 3.3.4 Error Prevention
              </gds-link>
              : For Web pages that…modify or delete user-controllable data...at
              least one of the following is true. Submissions must either be:
            </li>
            <ul>
              <li>Reversible</li>
              <li>Checked</li>
              <li>Confirmed</li>
            </ul>
            <li>
              <gds-link
                href="https://www.w3.org/TR/WCAG22/#target-size-minimum"
                underlined="true"
              >
                Success Criterion 2.5.8 Minimum Target Size
              </gds-link>
              : Targets have an area of at least 24 by 24 CSS pixels.
            </li>
            <li>
              <gds-link
                href="https://www.w3.org/TR/WCAG22/#dragging-movements"
                underlined="true"
              >
                Success Criterion 2.5.7 Dragging Movements
              </gds-link>
              : All functionality that uses a dragging movement for operation
              can be achieved by a single pointer without dragging.
            </li>
          </ul>
        </div>
        <Divider />

        <div className="section" id="assistive-technologies">
          <h2>Assistive Technologies (Coming Soon)</h2>
          <p>
            All components are tested to operate well with assistive
            technologies. We test keyboard navigation, usage on screen readers,
            and functionality on operating system accessibility features. We’ll
            have more information about our process coming soon.
          </p>
        </div>
        <Divider />
      </FoundationPage>
    </Layout>
  );
}
